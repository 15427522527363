<template>
  <component :is="containerTag" :class="containerClass">
    <slot v-if="loading" name="loading" :loading="loading">
      <VFlex xs12>
        <BaseLoader v-if="loading" bg-color="#FFF" color="#BBB" />
      </VFlex>
    </slot>
    <template v-else>
      <slot
        v-for="(feed, index) in feeds"
        name="feeds"
        :index="index"
        :feed="feed"
      />
      <slot name="error" :error="error" />
    </template>
  </component>
</template>

<script>
import axios from 'axios'
import _get from 'lodash/get'
export default {
  name: 'RestoInstagram',

  props: {
    /*
     * Instagram access token.
     */
    token: {
      type: String,
      required: true,
    },

    /*
     * Media Fields (see https://developers.facebook.com/docs/instagram-basic-display-api/reference/media#fields)
     */

    fields: {
      type: [String, Array],
      default: 'media_url,media_type,permalink,caption,thumbnail_url',
    },

    /*
     * Numbers of feed.
     */
    count: {
      type: Number,
      default: 3,
      required: false,
    },

    /*
     * Kinds of media to filter (Can be IMAGE, VIDEO, or CAROUSEL_ALBUM.).
     */
    mediatypes: {
      type: Array,
      default: () => ['IMAGE', 'VIDEO', 'CAROUSEL_ALBUM'],
    },

    // class for container div
    containerClass: {
      type: String,
      default: '',
      required: false,
    },
    containerTag: {
      type: String,
      default: 'div',
      required: false,
    },
  },

  data: () => ({
    error: '',
    loading: false,
    feeds: [],
  }),

  mounted() {
    // console.log('mounting vue-instagram...')
    this.getUserFeed()
  },

  methods: {
    async getUserFeed() {
      // console.log('vue-instagram getting user feed...')
      this.loading = true

      const urlEndpoint = 'https://graph.instagram.com/me/media'
      const fields =
        typeof this.fields === 'string' ? this.fields : this.fields.join(',')
      try {
        let response = await axios.get(urlEndpoint, {
          params: {
            access_token: this.token,
            fields: fields,
            count: this.count,
          },
        })
        for (const n in response.data.data) {
          if (this.mediatypes.includes(response.data.data[n].media_type)) {
            this.feeds.push(response.data.data[n])
            if (this.feeds.length >= this.count) {
              return
            }
          }
        }
      } catch (error) {
        this.error = _get(
          error,
          'response.data',
          'There was an error loading the Instagram feed'
        )
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
